<template>
  <div class="dashboard-tabs flex flex-col max-h-full">
    <div class="dashboard-tabs__header">
      <ul class="list">
        <li
          v-for="tab in filteredTabs"
          :key="tab.id"
          class="tab cursor-pointer"
          :class="{
            'tab--selected': selected === tab.id,
            setTabWidth: isVerificationCheck,
            disableTab:
              isVerificationCheck && isTabDisable && tab.label != 'Run Check',
          }"
          @click="clickHandler($event, tab.id)"
        >
          <span v-if="tab.isIcon">
            <component :is="tab.iconSvgName" fill="#ffff"/>
        </span>
        <span class="px-1">{{ tab.label }}</span>
          <span v-if="tab.id === 'all' && tab.isCount">({{ truncateAfterFourDigit(allCounts) }})</span>
          <span v-else-if="tab.id === 'unknown' && tab.isCount">({{ truncateAfterFourDigit(unknownCounts) }})</span>
          <span v-else-if="tab.id === 'confirmed' && tab.isCount">({{ truncateAfterFourDigit(confirmedCounts) }})</span>
          <span v-else-if="tab.id === 'potential' && tab.isCount">({{ truncateAfterFourDigit(potentialCount) }})</span>
          <span v-else-if="tab.id === 'irrelevant' && tab.isCount">({{ truncateAfterFourDigit(irrelevantCount) }})</span>
          <span v-else-if="tab.isCount">({{ tab.counts }})</span>
        </li>
      </ul>
      <div class="additional-controls">
        <slot />
      </div>
    </div>
    <!-- <div class="mt-2 flex-col flex-1 min-w-full bg-scroll overflow-y-scroll scroll-bar" style="height: 64vh;">
      <slot name="content" />
      <template v-if="$slots['content-' + selected]">
        <slot :name="'content-' + selected" />
      </template>
    </div> -->
  </div>
</template>

<script>
import Cross from "../../assets/svg/cross-red.svg";
import Check from "../../assets/svg/check.svg";
import Question from "../../assets/svg/question.svg";
import MinusIcon from "@shared/assets/minus-icon.svg";
export default {
  name: "tabs",
  props: {
    tabs: Array,
    selected: [String, Number],
    isVerificationCheck: {
      type: Boolean,
    },
    isTabDisable: {
      type: Boolean,
    },
    unknownCounts: {
      type: Number,
      default: 0,
    },
    confirmedCounts: {
      type: Number,
      default: 0,
    },
    allCounts: {
      type: Number,
      default: 0,
    },
    potentialCount: {
      type: Number,
      default: 0,
    },
    irrelevantCount: {
      type: Number,
      default: 0,
    },
    hideTabs: {
      type: Array,
      default: () => [],
    }
    // isIcon: {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
    // iconSvgName: {
    //   type: String,
    //   required: false,
    //   default: "",
    // },
    // isCount: {
    //     type: Boolean,
    //   required: false,
    //   default: false,
    // },
    // counts: {
    //   type: String,
    //   required: false,
    //   default: "",
    // },
  },
  components: {
    Cross,
    Check,
    Question,
    MinusIcon,
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter(x=> !this.hideTabs.includes(x.id));
    }
  },
  methods: {
    clickHandler(event, id) {
        this.$emit('select', event, id)
    },
    truncateAfterFourDigit(count) {
      let formatCount = ""
      if( count.toString().length > 4) {
        formatCount = count.toString().substring(0,4) + '...'
        return formatCount;
      } else {
        return count;
      }
      
    }
    // getComponent(tab) {
    //   if(tab.id === selected && tab.id === 'all') {

    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.dashboard-tabs {
  width: 100%;
  &__header {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    
    .list {
      display: flex;
      align-items: center;
      // gap: 20px;
      .tab {
        height: 32px;
        // padding: 2rem 2.5rem;
        // min-width: 160px;
        padding: 0 16px;
        // background-color: #efefefb5;
        background-color: white;
        color: #a6a6a6;
        // @apply text-base-content;
        // letter-spacing: 0.095em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px 12px 0 0;
        font-size: 14px;
        // font-weight: 600;
        border: 1px solid #f0f0f0;
        // border-bottom: 0;
        &--selected {
          // background-color: #0D69D5;
          @apply bg-primary;
          @apply border-primary;
          // color: #fff;
          @apply text-white-text;
        }
      }
    }
  }
  &__content {
    margin-top: 20px;
  }
}

.setTabWidth {
  width: 118px;
}
.disableTab {
  background: #e1e1e1 !important;
  border: none !important;
  cursor: not-allowed !important;
  color: rgb(192, 192, 192) !important;
}
</style>
